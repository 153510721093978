export const themeConsts = {
  pix: "#28D4FF",
  smile: "#FF2D55",
  black: "#262626",
  lightGrey: "#F3F3F3",
  mediumGrey: "#AEAEB2",
  darkGrey: "#86868A",
  nightBlue: "#2C3543",
  lightPurple: "#D389F9",
  deepPurple: "#6804E8",
  darkCyan: "#00BAE9",
};
